import React, { useState } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Logo from "../../../assets/icons/logo-main-dark.svg";

const Header = ({style}) => {

  const [burgerState, setBurgerState] = useState('inactive');

  function toggleState() {
    burgerState === 'active' ? setBurgerState('inactive') : setBurgerState('active');
  }

  const navItems = [
    {
      title: 'Home',
      link: '#'
    },
    {
      title: 'Services',
      link: '#services'
    },
    {
      title: 'Testimonials',
      link: '#testimonials'
    },
    {
      title: 'Contact',
      link: '#contact'
    }
  ]

  return (
<div className={style === 'static' ? 'header header--static' : 'header'}>
    <div className="top__gutter text-sm">
      <div className="top__gutter__left flex align-content-center">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
        </svg>
      <a className="ml-2" href="mailto:info@thedetailingghost.co.nz">info@thedetailingghost.co.nz</a>
      </div>
      <div className="top__gutter__right flex flex-wrap md:justify-end">
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
      </svg> Auckland: <a href="tel:020 4157 2294" className="ml-2">020 4157 2294</a> <span className="mx-2">|</span> Christchurch: <a className="ml-2" href="tel:022 171 0076">022 171 0076</a>
      </div>
    </div>
    <nav className={`navigation flex items-center justify-between flex-wrap bg-teal-500 px-5 py-2 ${burgerState === "active" ? "is-open" : ""}`}>
      {/* Logo */}
      <div className="flex items-center flex-shrink-0 text-white mr-6">
      <a href="/">
        <Logo width="100" height="100" />
      </a>
      </div>
        {/* Mobile Nav Button */}
        <div className="nav__mobileButton block lg:hidden">
        <button className="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white" onClick={() => toggleState()}>
          <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
        </button>
      </div>
      <div className={`nav__container ${burgerState === "active" ? "is-open" : ""}`}>
      {/* Main Nav */}
      <div className="nav w-full block lg:flex lg:items-center lg:w-auto">
          {navItems.map((item) => {
            return (
              <AnchorLink
              to={item.link}
              onClick={() => setBurgerState('inactive')}
               className="nav__item">
              {item.title}</AnchorLink>
            )
          })}
      </div>
      {/* Social Icons Etc */}
      {/* <div>
        <a href="#" className="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-teal-500 hover:bg-white mt-4 lg:mt-0">Download</a>
      </div> */}
      </div>
    </nav>
    </div>
  )
}

export default Header